<template>
  <div>
    <el-row :gutter="15">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="24">
          <el-form-item label="标题" prop="HiringCount">
            <el-input v-model="formData.HiringCount" placeholder="请输入招聘人数" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="招收专业" prop="Department">
            <el-select v-model="formData.Department" placeholder="请选择专业" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in DepartmentOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
         <el-col :span="12">
          <el-form-item label="招收人数" prop="Department">
            <el-input v-model="formData.HiringCount" placeholder="请输入招收人数" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="招生说明" prop="Education">
            <el-input v-model="formData.JobRequirements" type="textarea" placeholder="请输入招生要求" :autosize="{minRows: 6, maxRows: 6}"
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
     
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm">发布</el-button>
            <el-button @click="handleCancel">返回</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        JobType: undefined,
        JobCategoryId: [1, 2],
        Name: undefined,
        HiringCount: undefined,
        Experience: undefined,
        Education: undefined,
        MinSalary: undefined,
        MaxSalary: undefined,
        JobDescription: undefined,
        JobRequirements: undefined,
        Keyword: undefined,
        Benefits: undefined,
        AreaId: undefined,
        Department: undefined,
      },
      rules: {
        JobType: [{
          required: true,
          message: '请选择招聘类型',
          trigger: 'change'
        }],
        JobCategoryId: [{
          required: true,
          type: 'array',
          message: '请至少选择一个职位类型',
          trigger: 'change'
        }],
        Name: [{
          required: true,
          message: '请输入职位名称',
          trigger: 'blur'
        }],
        HiringCount: [{
          required: true,
          message: '请输入招聘人数',
          trigger: 'blur'
        }],
        Experience: [{
          required: true,
          message: '请选择工作经验',
          trigger: 'change'
        }],
        Education: [{
          required: true,
          message: '请选择学历要求',
          trigger: 'change'
        }],
        MinSalary: [{
          required: true,
          message: '请输入最低薪资',
          trigger: 'blur'
        }],
        MaxSalary: [{
          required: true,
          message: '请输入最高薪资',
          trigger: 'blur'
        }],
        JobDescription: [{
          required: true,
          message: '请输入岗位职责',
          trigger: 'blur'
        }],
        JobRequirements: [{
          required: true,
          message: '请输入任职要求',
          trigger: 'blur'
        }],
        Keyword: [],
        Benefits: [],
        AreaId: [{
          required: true,
          message: '请选择工作地点',
          trigger: 'change'
        }],
        Department: [{
          required: true,
          message: '请选择所属部门',
          trigger: 'change'
        }],
      },
      JobTypeOptions: [{
        "label": "全职",
        "value": 1
      }, {
        "label": "兼职",
        "value": 2
      }, {
        "label": "实习生",
        "value": 3
      }],
      JobCategoryIdOptions: [],
      ExperienceOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      EducationOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      AreaIdOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      DepartmentOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      JobCategoryIdProps: {
        "multiple": false,
        "label": "label",
        "value": "value",
        "children": "children"
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getJobCategoryIdOptions()
  },
  mounted() { },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    getJobCategoryIdOptions() {
      // 注意：this.$axios是通过Vue.prototype.$axios = axios挂载产生的
      // this.$axios({
      //   method: 'get',
      //   url: 'https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/cascaderList'
      // }).then(resp => {
      //   var {
      //     data
      //   } = resp
      //   this.JobCategoryIdOptions = data.list
      // })
    },
    handleCancel() {
      this.$router.go(-1)
    }
  }
}

</script>
<style>
</style>
